<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="modelRef" @finish="onSearch">
			    <a-row>
			        <a-form-item name="title" class="ui-form-item" label="任务名称">
			            <a-input v-model:value="modelRef.title" placeholder="请输入"/>
			        </a-form-item>
			
			        <a-form-item name="userName" class="ui-form-item" label="操作人姓名">
			            <a-input v-model:value="modelRef.userName" placeholder="请输入"></a-input>
			        </a-form-item>
			    </a-row>
			
			    <a-row>
			        <a-col :span="10">
			        </a-col>
			        <a-col :span="14" style="text-align: right;">
			            <a-button style="margin-right: 20px;" type="primary" html-type="submit">搜索</a-button>
			            <a-button @click="resetBtn">重置</a-button>
			        </a-col>
			    </a-row>
			</a-form>
			<a-table style="margin-top: 10px;" :dataSource="dataList" :columns="dataColumns" rowKey="id" :pagination="pagination">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'type'">
						{{ transType(record.type) }}
					</template>
					<template v-if="column.key === 'status'">
						{{ transStatus(record.status) }}
					</template>
					<template v-if="column.key === 'fileStatus'">
						{{ transFileStatus(record.fileStatus) }}
					</template>
					<template v-if="column.key === 'finishTime'">
						{{ transDateTime(record.finishTime) }}
					</template>
					<template v-if="column.key === 'action'">
						<a v-permission="['system_task_list_download']" v-if="record.fileStatus == 1" :href="record.filePath">下载文件</a>
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import { getExportList } from '@/service/modules/export.js';
	export default {
		data() {
			return {
				loading: false,
				modelRef: {},
				searchData: {},
				dataColumns: [{
					title: '任务名称',
					dataIndex: 'title'
				}, {
					title: '导出类型',
					key: 'type'
				}, {
					title: '操作人姓名',
					dataIndex: 'userName'
				}, {
					title: '任务状态',
					key: 'status'
				}, {
					title: '文件状态',
					key: 'fileStatus'
				}, {
					title: '完成时间',
					key: 'finishTime'
				}, {
					title: '操作',
					key:'action'
				}],
				dataList:[],
				pagination: {
				    showSizeChanger: true,
				    showQuickJumper: true,
				    size: "middle",
				    showTotal: (total) => {
				      return "共 " + total + " 条";
				    },
				    total: 0,
				    current: 1,
				    pageSize: 20,
				    onChange: (page) => {
				      this.pagination.current = page;
				      this.getData();
				    },
				    onShowSizeChange: (page, pageSize) => {
				      this.pagination.current = 1;
				      this.pagination.pageSize = pageSize;
				      this.getData();
				    }
				}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			transType(val) {
				if (val === 'yearCardConsume') return '年卡消费报表导出';
				if (val === 'yearCardVolume') return '年卡发行报表导出';
				if (val === 'timesCardConsume') return '次卡消费报表导出';
				if (val === 'timesCardVolume') return '次卡发行报表导出';
				if (val === 'couponConsume') return '兑换券消费报表导出';
				if (val === 'couponVolume') return '兑换券发行报表导出';
				if (val === 'baseYearCard') return '年卡信息导出';
				if (val === 'baseYearCardConsume') return '年卡消费导出';
				if (val === 'baseTimesCard') return '次卡信息导出';
				if (val === 'numCardDetailReport') return '次卡信息明细表导出';
				if (val === 'baseTimesCardVolume') return '次卡消费导出';
				if (val === 'baseCoupon') return '兑换券信息导出';
				if (val === 'storeOrder') return '商城订单导出';
				if (val === 'snackOrder') return '卖品订单导出';
				if (val === 'revenueStatement') return '营收报表导出';
				if (val === 'seatStatistics') return '人数统计报表导出';
				if (val === 'ticketOrderStatement') return '影票报表导出';
				if (val === 'cashTicketStatistics') return '现金购票实时统计报表导出';
				if (val === 'snackCoupon') return '卖品券批次详情列表导出';
				if (val === 'snackCouponsList') return '卖品券列表导出';
				if (val === 'userSnackCoupon') return '卖品券信息列表导出';
				if (val === 'snackConsume') return '卖品券消费列表导出';
				if (val === 'storeSalesStatistical') return '商城销售明细表导出';
				if (val === 'cashTicketDetailStatistical') return '微信支付购票明细表导出';
				if (val === 'diffTicketStatistical') return '补差明细表导出';
				if (val === 'snackStatisticalVerification') return '卖品核销明细表导出';
				if (val === 'snackStatisticsDetail') return '卖品销售明细表导出';
				if (val === 'couponsList') return '兑换券列表导出';
				if (val === 'userCoupons') return '兑换券信息导出';
				if (val === 'couponConsumption') return '兑换券消费导出';
				if (val === 'scanPayReport') return '前台扫码付款报表导出';
				if (val === 'seatStatisticsCinemaDay') return '电影日人数统计表导出';
				if (val === 'ticketStatementDetail') return '影票明细表导出';
				if (val === 'distributorReport') return '分销报表导出';
				if (val === 'activityCouponClaimRecord') return '推广券活动领取记录导出';
				if (val === 'baseEntityCardConsume') return '影城会员卡消费报表导出';
				if (val === 'entityReport') return '影城会员卡充值报表导出';
				if (val === 'baseRechargeCardVolume') return '线上会员卡消费报表导出';
				if (val === 'rechargeReport') return '线上会员卡充值报表导出';
				if (val === 'appCardList') return '线上会员卡列表导出';
				if (val === 'baseRechargeCard') return '线上会员卡信息导出';
				if (val === 'tikTokTicketConsume') return '抖音券消费列表导出';
				if (val === 'tikTokDetail') return '抖音明细表导出';
				if (val === 'ticketOrderVerifyStatement') return '影票售后报表导出';
				if (val === 'ticketOrderSeatStatement') return '影票座位明细报表导出';
        if (val === 'cardRecord') return '会员卡余额变动导出';
 			},
			transStatus(val) {
				if (val === 0) return '已就绪';
				if (val === 1) return '进行中';
				if (val === 2) return '已完成';
				if (val === 3) return '异常';
			},
			transFileStatus(val) {
				if (val === 0) return '未生成';
				if (val === 1) return '可下载';
				if (val === 2) return '已清除';
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.modelRef));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getExportList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code ===200) {
						this.pagination.total = ret.data.totalCount;
						this.dataList = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			resetBtn() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form-item {
		margin-right: 20px;
	}
</style>
